import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'files-storage',
  templateUrl: './files-storage.component.html',
  styleUrls: ['./files-storage.component.scss'],
})
export class FilesStorageComponent implements OnInit, OnDestroy {
  TYPES = {
    NONE: undefined,
    CLOUD_STORAGE: 'GOOGLE_BUCKET',
  };
  @Input() set account(value: any) {
    if (!this.accountValue) {
      this.accountValue = value;
    } else if (this.accountValue.id !== value.id) {
      this.accountValue = value;
      this.getStorage();
    }
  }
  accountValue: any;
  subscriptions: any = {};
  storageData: any = {};
  configuration: any = {};
  constructor(private accountService: AccountService) {}

  ngOnInit(): void {
    this.getStorage();
  }

  getStorage() {
    this.subscriptions['Storage'] = this.accountService
      .getStorage(this.accountValue.id)
      .pipe(
        catchError((e) => {
          if (e.status === 404) {
            return of({ accountId: this.accountValue.id });
          }
        })
      )
      .subscribe((data) => {
        this.storageData = data;
        this.configuration = this.storageData.conf || {};
      });
  }

  doSubmit() {
    let body;
    switch (this.storageData.type) {
      case this.TYPES.NONE:
        this.subscriptions['deleteStorage'] = this.accountService.deleteStorage(this.accountValue.id).subscribe();
        break;
      case this.TYPES.CLOUD_STORAGE:
        body = {
          ...this.storageData,
          conf: {
            ...this.configuration,
            serviceAccount: this.accountValue.serviceAccount,
            projectId: this.accountValue.projectId,
          },
        };

        if (this.storageData.id) {
          this.subscriptions['updateStorage'] = this.accountService.updateStorage(body).subscribe((data) => {
            this.storageData = data;
            this.configuration = this.storageData.conf || {};
          });
        } else {
          this.subscriptions['createStorage'] = this.accountService.createStorage(body).subscribe((data) => {
            this.storageData = data;
            this.configuration = this.storageData.conf || {};
          });
        }
        break;

      default:
        break;
    }
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key) => {
      this.subscriptions[key].unsubscribe();
    });
  }
}
