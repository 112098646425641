import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IQuotas } from '../components/account-settings/account-fields/quotas/quotas.model';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(private http: HttpClient) {}

  getAccount(idAccount: number) {
    return this.http.get(`${environment.API.orchestrator_configurator}/accounts/${idAccount}`);
  }

  updateAccount(data: any) {
    return this.http.put(`${environment.API.orchestrator_configurator}/accounts`, data);
  }

  updateServiceAccount(accountId, data: any) {
    const httpOptions = {
      params: new HttpParams().set('accountId', accountId.toString()),
    };

    return this.http.put(`${environment.API.orchestrator_configurator}/accounts/serviceAccount`, data, httpOptions);
  }

  createAccount(data: any) {
    return this.http.post(`${environment.API.orchestrator_configurator}/accounts`, data);
  }

  deleteAccount(accountId: number) {
    const httpOptions = {
      params: new HttpParams().set('accountId', accountId.toString()),
    };
    return this.http.delete(`${environment.API.orchestrator_configurator}/accounts`, httpOptions);
  }

  getProducts() {
    return this.http.get(`${environment.API.orchestrator_configurator}/accounts/products`);
  }

  getQuotas(idAccount: number) {
    return this.http.get<IQuotas>(`${environment.API.orchestrator_configurator}/planning/${idAccount}`);
  }

  createQuotas(data: any) {
    return this.http.post(`${environment.API.orchestrator_configurator}/planning`, data);
  }

  updateQuotas(data: any) {
    return this.http.put(`${environment.API.orchestrator_configurator}/planning`, data);
  }

  getAgents(idAccount: number) {
    return this.http.get(`${environment.API.orchestrator_configurator}/agentbot/${idAccount}`);
  }

  getAgentTypes() {
    return this.http.get(`${environment.API.orchestrator_configurator}/agentbot/types`);
  }

  updateAgents(idAccount: number, data: any) {
    return this.http.put(`${environment.API.orchestrator_configurator}/agentbot/${idAccount}`, data);
  }

  deleteAgent(idAgent: number) {
    return this.http.delete(`${environment.API.orchestrator_configurator}/agentbot?agentBotId=${idAgent}`);
  }

  getUsers(idAccount: number) {
    return this.http.get(`${environment.API.orchestrator_configurator}/users/${idAccount}`);
  }

  updateUsers(idAccount: number, data: any) {
    return this.http.put(`${environment.API.orchestrator_configurator}/users/${idAccount}`, data);
  }

  deleteUser(idAccount: number, userId: number) {
    return this.http.delete(`${environment.API.orchestrator_configurator}/users/${idAccount}/${userId}`);
  }

  getStorage(idAccount: number) {
    return this.http.get(`${environment.API.orchestrator_configurator}/storage/${idAccount}`);
  }

  createStorage(data: any) {
    return this.http.post(`${environment.API.orchestrator_configurator}/storage`, data);
  }

  updateStorage(data: any) {
    return this.http.put(`${environment.API.orchestrator_configurator}/storage`, data);
  }

  deleteStorage(idAccount: number) {
    return this.http.delete(`${environment.API.orchestrator_configurator}/storage/${idAccount}`);
  }
}
