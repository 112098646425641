<div class="service_account_modal" [spinnerLoader]="updateSubscription?.closed">
    <div class="modal-header align-items-center">
        <span class="modal-title">Change Service Account</span>
        <button type="button" class="btn brand_default" aria-label="Update" (click)="update()">Update</button>
    </div>
    <div class="modal-body">
        <div class="body_content">
            <div class="form-row">
                <div class="form-group col-12">
                    <label for="serviceAccount">Service Account*</label>
                    <textarea rows="10" id="serviceAccount" class="form-control" [formControl]="serviceAccountForm" [ngClass]="{ 'is-invalid': serviceAccountForm?.invalid && submitted }"></textarea>
                    <div class="invalid-feedback" *ngIf="submitted && serviceAccountForm?.errors?.required">Service Account is required</div>
                    <div class="invalid-feedback" *ngIf="submitted && serviceAccountForm?.errors?.jsonInvalid">Invalid Json</div>
                </div>
            </div>
        </div>
    </div>
</div>
