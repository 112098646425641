import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'customer-settings',
  templateUrl: './customer-settings.component.html',
  styleUrls: ['./customer-settings.component.scss'],
})
export class CustomerSettingsComponent implements OnDestroy {
  customerName;
  customerDescription;
  subscriptions: any = {};

  constructor(private router: Router, private customerService: CustomerService) {}

  doSubmit() {
    this.subscriptions['createCustomer'] = this.customerService.createCustomer({ name: this.customerName, description: this.customerDescription }).subscribe(() => {
      this.goToCustomer();
    });
  }

  goToCustomer() {
    this.router.navigate(['customers']);
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key) => {
      this.subscriptions[key].unsubscribe();
    });
  }
}
