<div class="confirmation_modal">
    <div class="modal-header align-items-center">
        <span class="modal-title">{{ title }}</span>
        <button type="button" class="close" aria-label="Close" (click)="abort()">
            <i class="fal fa-times" style="color: white;" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="body_content">
            <p>
                Once you delete 
                <span *ngIf="type === 'account'">an</span>
                <span *ngIf="type !== 'account'">a</span>
                {{ type }}, there is no going back. Please be certain.
            </p>
            <p>
                This action cannot be undone. This will permanently delete the <strong>{{ elemName }}</strong> {{ type }}.
            </p>
            <div>
                Please type <strong>delete {{ elemName }}</strong> to confirm.
            </div>
            <form action="">
                <input type="text" id="name" class="w-50 form-control" name="name" maxlength="50" [(ngModel)]="confirmDelete" required #accountName="ngModel" aria-label="Element Name"/>
            </form>
        </div>
    </div>
    <div class="modal-footer border-0">
        <button type="button" class="btn brand_default" (click)="confirm(elementId)">Delete {{ type }}</button>
    </div>
</div>