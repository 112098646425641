import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'confirm-delete-modal',
  templateUrl: './confirm-delete-modal.component.html',
  styleUrls: ['./confirm-delete-modal.component.scss']
})
export class ConfirmDeleteModalComponent {

  title: string;
  type: string;
  elementId: string | number;
  elemName: string;
  confirmDelete: string;

  constructor(private activeModal: NgbActiveModal) { }

  abort(): void {
    this.activeModal.close();
  }

  confirm(id): void {
    const nameToDelete = this.confirmDelete.substring(7);
    if (nameToDelete === this.elemName) {
      this.activeModal.close(id);
    }
  }

}
