<form name="users" #form="ngForm" class="form_container" (submit)="form.valid && doSubmit()" autocomplete="off">
    <ng-template [spinnerLoader]="subscriptions['updateUsers']?.closed"></ng-template>
    <div class="header_wrapper sticky">
        <div class="row justify-content-between">
            <div class="col-auto my-auto">
                <span>Administrator users</span>
            </div>
            <div class="col-auto my-auto">
                <button class="btn brand_default">Save</button>
            </div>
        </div>
    </div>
    <div class="panel_wrapper">
        <div class="row" *ngFor="let user of users; let index = index">
            <div class="col-11">
                <div class="form-row">
                    <div class="form-group col-4">
                        <label [for]="'firstName' + index">First Name*</label>
                        <input type="text" [id]="'firstName' + index" class="form-control" maxlength="50" [(ngModel)]="user.firstName" [name]="'firstName' + index" required [ngClass]="{ 'is-invalid': form.form.controls['firstName' + index]?.invalid && form.submitted }" />
                        <div class="invalid-feedback" *ngIf="form.form.controls['firstName' + index]?.errors?.required">First Name is required</div>
                    </div>
                    <div class="form-group col-4">
                        <label [for]="'lastName' + index">Last Name*</label>
                        <input type="text" [id]="'lastName' + index" class="form-control" maxlength="50" [(ngModel)]="user.lastName" [name]="'lastName' + index" required [ngClass]="{ 'is-invalid': form.form.controls['lastName' + index]?.invalid && form.submitted }" />
                        <div class="invalid-feedback" *ngIf="form.form.controls['lastName' + index]?.errors?.required">Last Name is required</div>
                    </div>
                    <div class="form-group col-4">
                        <label [for]="'email' + index">E-mail*</label>
                        <input
                            type="text"
                            [id]="'email' + index"
                            class="form-control"
                            maxlength="200"
                            [(ngModel)]="user.email"
                            [name]="'email' + index"
                            required
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                            [ngClass]="{ 'is-invalid': form.form.controls['email' + index]?.invalid && form.submitted }"
                        />
                        <div class="invalid-feedback" *ngIf="form.form.controls['email' + index]?.errors?.required">Email is required</div>
                        <div class="invalid-feedback" *ngIf="form.form.controls['email' + index]?.errors?.pattern">Email's format not valid</div>
                    </div>
                </div>
            </div>
            <div class="col-1">
                <span class="fa fa-times" (click)="deleteUser(index)"></span>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <a href="javascript:void(0)" (click)="addUser()">+ Add user</a>
            </div>
        </div>
    </div>
</form>
