import { Injectable, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { NotificationModalComponent } from '../components//modals/notification-modal/notification-modal.component';
import { NotificationModalOptions } from '../interfaces/notification-modal-options';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  toasts: any[] = [];
  modalSpinner: any;

  constructor(private modalService: NgbModal) { }

  openModal(options: NotificationModalOptions): Observable<any> {
    const modalRef = this.modalService.open(NotificationModalComponent, {
      size: options.size || null
    })
    modalRef.componentInstance.options = options;

    return new Observable<any>(observer => {
      modalRef.result.then((result: boolean) => {
        observer.next(result);
      }, (reason: any) => {
        observer.next(reason);
      })
    });
  }

  showToast(textOrTpl: string | TemplateRef<any>, options: { type?: 'success' | 'danger' | 'warning', delay?: number } = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  removeToast(toast: any) {
    this.toasts = this.toasts.filter(t => t != toast)
  }

  showModalSpinner() {
    this.modalSpinner = this.modalService.open(NotificationModalComponent, {
      size: 'sm',
      centered: true,
      keyboard: false
    });
    this.modalSpinner.componentInstance.options = {
      type: 'spinner'
    };
  }

  hideModalSpinner() {
    this.modalSpinner.close();
  }

}
