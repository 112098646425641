import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { NotificationService } from '../services/notification.service';
import { environment } from 'src/environments/environment';

const excludedApiStatus: Array<any> = [
  {
    url: `${environment.API.orchestrator_configurator}/planning/:id`,
    method: 'GET',
    status: [404],
  },
  {
    url: `${environment.API.orchestrator_configurator}/storage/:id`,
    method: 'GET',
    status: [404],
  },
];

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private notificationService: NotificationService, private modalService: NgbModal) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status !== 403 && error.status !== 401 && !this.excludeApi(req, error)) {
          this.notificationService.openModal({
            title: 'ERROR',
            message: 'Ops... Something went wrong and it was impossible to complete the operation!',
            type: 'danger',
            choice: 'support',
            serviceError: {
              message: error?.error?.message ?? 'Generic Error',
              code: error.status,
            },
          });
        }
        return throwError(error);
      })
    );
  }

  private excludeApi(req: HttpRequest<any>, error: HttpErrorResponse) {
    return excludedApiStatus.find((api) => {
      if (
        (req.url === api.url || req.url.substring(0, req.url.lastIndexOf('/') + 1) === api.url.substring(0, api.url.lastIndexOf('/') + 1)) &&
        req.method === api.method &&
        (!api.status || api.status.length === 0 || api.status.indexOf(error.status) >= 0)
      ) {
        return true;
      } else {
        return false;
      }
    });
  }
}
