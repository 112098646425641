import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authConfig: AuthConfig = {
  clockSkewInSec: 60,
  issuer: environment.keycloak_issuer,
  redirectUri: window.location.origin + '/',
  postLogoutRedirectUri: window.location.origin + '/',
  clientId: environment.keycloak_client,
  scope: 'openid profile email',
  responseType: 'code',
  requireHttps: false,
  disableAtHashCheck: true,
  showDebugInformation: environment.production ? false : true,
  customTokenParameters: ['refresh_expires_in', 'expires_in'],
};
