import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountService } from 'src/app/services/account.service';

const jsonValidator = (control: AbstractControl): ValidationErrors | null => {
  if (!control.value) return null;
  try {
    JSON.parse(control.value);
  } catch (e) {
    return { jsonInvalid: true };
  }

  return null;
};

@Component({
  selector: 'service-account-modal',
  templateUrl: './service-account-modal.component.html',
  styleUrls: ['./service-account-modal.component.scss'],
})
export class ServiceAccountModalComponent implements OnInit, OnDestroy {
  accountId;
  serviceAccountForm: FormControl;
  submitted;
  updateSubscription;
  constructor(private accountService: AccountService, private activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.serviceAccountForm = new FormControl('', [Validators.required, jsonValidator]);
  }
  ngOnDestroy(): void {
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }
  }
  update() {
    this.submitted = true;
    if (this.serviceAccountForm.invalid) {
      return;
    }
    this.updateSubscription = this.accountService.updateServiceAccount(this.accountId, this.serviceAccountForm.value).subscribe(() => this.activeModal.close(true));
  }
}
