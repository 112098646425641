<form name="agents" #form="ngForm" class="form_container"  (submit)="doSubmit(form)" autocomplete="off">
    <ng-template [spinnerLoader]="subscriptions['updateAgents']?.closed"></ng-template>
    <ng-template [spinnerLoader]="subscriptions['deleteAgent']?.closed"></ng-template>
    <div class="header_wrapper sticky">
        <div class="row justify-content-between">
            <div class="col-auto my-auto">
                <span>Virtual agents</span>
            </div>
            <div class="col-auto my-auto">
                <button class="btn brand_default">Save</button>
            </div>
        </div>
    </div>
    <div class="panel_wrapper">
        <div class="form-row">
            <div class="form-group col">
                <div class="btn-group btn-group-toggle flex-wrap" ngbRadioGroup name="radioBasic" [(ngModel)]="agentSelection">
                    <label ngbButtonLabel class="brand_default" [ngClass]="{'cancellable': index > 1}" *ngFor="let agent of agentData; let index = index">
                        <input ngbButton type="radio" [value]="agent"> {{agent.name}} <div class="div-delete" (click)="deleteAgent(agent.id, index)"><span class="fa fa-times"></span></div>
                    </label>
                </div>
                <a href="javascript:void(0)" (click)="addAgent()">+ Add agent</a>
            </div>
        </div>
        <div class="form-row" *ngFor="let agent of agentData; let index = index" [ngClass]="{'d-none': agent != agentSelection}">
            <div class="form-group col-3">
                <div class="form-row">
                    <div class="form-group col">
                        <label [for]="index + 'agentType'">NLU Type*</label>
                        <select [id]="index + 'agentType'" class="custom-select" [(ngModel)]="agent.agentType" [name]="index + 'agentType'" required [ngClass]="{'is-invalid': form.form.controls[index + 'agentType']?.invalid && form.submitted}">
                            <option [hidden]="true" [value]="null"></option>
                            <option *ngFor="let agentTypeOption of agentTypes" [value]="agentTypeOption">{{agentTypeOption}}</option>
                        </select>
                        <div class="invalid-feedback" *ngIf="form.form.controls[index + 'agentType']?.errors?.required">Agent Type is required</div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col">
                        <label [for]="'da_projectId'+index">Project ID*</label>
                        <input type="text" [id]="'da_projectId'+index" class="form-control" [(ngModel)]="agent.projectId" [name]="index+'projectId'" required [ngClass]="{'is-invalid': form.form.controls[index+'projectId']?.invalid && form.submitted}">
                        <div class="invalid-feedback" *ngIf="form.form.controls[index+'projectId']?.errors?.required">Project ID is required</div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col">
                        <label [for]="index + 'name'">Agent name*</label>
                        <input type="text" [id]="index + 'name'" class="form-control" maxlength="150" [(ngModel)]="agent.name" [name]="index + 'name'" required [ngClass]="{'is-invalid': form.form.controls[index + 'name']?.invalid && form.submitted}">
                        <div class="invalid-feedback" *ngIf="form.form.controls[index + 'name']?.errors?.required">Agent name is required</div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col" *ngIf="agent.agentType === 'Dialogflow CX'">
                        <label [for]="index + agentId">Agent ID*</label>
                        <input [name]="index + 'agentId'" type="text" [id]="index + 'agentId'" class="form-control" [(ngModel)]="agent.agentId" required [ngClass]="{'is-invalid': form.form.get(index + 'agentId')?.invalid && form.submitted}">
                        <div class="invalid-feedback" *ngIf="form.form.get(index + 'agentId')?.errors?.invalid">Agent ID is required</div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col" *ngIf="agent.agentType === 'Dialogflow CX'">
                        <label [for]="index + 'agentLocation'">Location*</label>
                        <input [name]="index + 'agentLocation'" type="text" [id]="index + 'agentLocation'" class="form-control" [(ngModel)]="agent.locationId" required [ngClass]="{'is-invalid': form.form.get(index + 'agentLocation')?.invalid && form.submitted}">
                        <div class="invalid-feedback" *ngIf="form.form.get(index + 'agentLocation')?.errors?.invalid">Loocation is required</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>