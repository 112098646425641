<form name="quotas" #form="ngForm" class="form_container" (submit)="form.valid && doSubmit()" *ngIf="canQuote()">
    <ng-template [spinnerLoader]="subscriptions['createQuotas']?.closed"></ng-template>
    <ng-template [spinnerLoader]="subscriptions['updateQuotas']?.closed"></ng-template>
    <div class="header_wrapper sticky">
        <div class="row justify-content-between">
            <div class="col-auto my-auto">
                <span>Quotas</span>
            </div>
            <div class="col-auto my-auto">
                <button class="btn brand_default">Save</button>
            </div>
        </div>
    </div>

    <div class="panel_wrapper cascade_wrap">
        <div class="form-row mb-4">
            <div class="col-12">NLU</div>
        </div>
        <div class="form-row">
            <div class="form-group col-3 text-center" *ngIf="isConnectorPurchased('ES')">
                <label for="df">Google Dialogflow ES</label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text" [class.disabled]="disableQuotaInput('DF')" (click)="subtract('DF')">-</div>
                    </div>
                    <input type="number" #df class="form-control text-center" (change)="updateThresholds('DF', +df.value, 0)" (blur)="onBlur('DF')" name="DF" [value]="getValue('DF')"/>
                    <div class="input-group-append">
                        <div class="input-group-text" (click)="add('DF')">+</div>
                    </div>
                </div>
                <span class="mt-1">(interactions)</span>
            </div>
            <div class="form-group col-3 text-center" *ngIf="isConnectorPurchased('ES')">
                <label for="dfAudio">Google Dialogflow ES Voice</label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text" [class.disabled]="disableQuotaInput('DF_AUDIO')" (click)="subtract('DF_AUDIO')">-</div>
                    </div>
                    <input type="number" #dfAudio class="form-control text-center" (change)="updateThresholds('DF_AUDIO', +dfAudio.value, 0)" (blur)="onBlur('DF_AUDIO')" name="DF_AUDIO" [value]="getValue('DF_AUDIO')" />
                    <div class="input-group-append">
                        <div class="input-group-text" (click)="add('DF_AUDIO')">+</div>
                    </div>
                </div>
                <span class="mt-1">(interactions)</span>
            </div>
            <div class="form-group col-3 text-center" *ngIf="isConnectorPurchased('CX')">
                <label for="totalDlf">Google Dialogflow CX</label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text" [class.disabled]="disableQuotaInput('DF_CX')" (click)="subtract('DF_CX')">-</div>
                    </div>
                    <input type="number" #dfCx class="form-control text-center" (change)="updateThresholds('DF_AUDIO', +dfCx.value, 0)" (blur)="onBlur('DF_CX')" name="DF_CX" [value]="getValue('DF_CX')" />
                    <div class="input-group-append">
                        <div class="input-group-text" (click)="add('DF_CX')">+</div>
                    </div>
                </div>
                <span class="mt-1">(interactions)</span>
            </div>
            <div class="form-group col-3 text-center" *ngIf="isConnectorPurchased('CX')">
                <label for="totalDlfCxAudio">Google Dialogflow CX Voice</label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text" [class.disabled]="disableQuotaInput('DFCX_AUDIO')" (click)="subtract('DFCX_AUDIO')">-</div>
                    </div>
                    <input type="number" #dfCxAudio class="form-control text-center" (change)="updateThresholds('DF_AUDIO', +dfCxAudio.value, 0)" (blur)="onBlur('DFCX_AUDIO')" name="DFCX_AUDIO" [value]="getValue('DFCX_AUDIO')" />
                    <div class="input-group-append">
                        <div class="input-group-text" (click)="add('DFCX_AUDIO')">+</div>
                    </div>
                </div>
                <span class="mt-1">(interactions)</span>
            </div>
        </div>
    </div>
    <div class="panel_wrapper cascade_wrap">
        <div class="form-row mb-4">
            <div class="col-12">Other</div>
        </div>
        <div class="form-row">
            <div class="form-group col-3 text-center" *ngIf="isConnectorPurchased('NL')">
                <label for="nl">Natural Language</label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text" [class.disabled]="disableQuotaInput('NL')" (click)="subtract('NL')">-</div>
                    </div>
                    <input type="number" #nl class="form-control text-center" (change)="updateThresholds('NL', +nl.value, 0)" (blur)="onBlur('NL')" name="NL" [value]="getValue('NL')" />
                    <div class="input-group-append">
                        <div class="input-group-text" (click)="add('NL')">+</div>
                    </div>
                </div>
                <span class="mt-1">(requests)</span>
            </div>
            <div class="form-group col-3 text-center" *ngIf="isConnectorPurchased('DLP')">
                <label for="dlp">Data Loss Prevention</label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text" [class.disabled]="disableQuotaInput('NL')" (click)="subtract('DLP')">-</div>
                    </div>
                    <input type="number" #dlp class="form-control text-center" (change)="updateThresholds('NL', +dlp.value, 0)" (blur)="onBlur('DLP')" name="DLP" [value]="getValue('DLP')" />
                    <div class="input-group-append">
                        <div class="input-group-text" (click)="add('DLP')">+</div>
                    </div>
                </div>
                <span class="mt-1">(characters)</span>
            </div>
            <div class="form-group col-3 text-center">
                <label for="t">Translate</label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text" [class.disabled]="disableQuotaInput('T')" (click)="subtract('T')">-</div>
                    </div>
                    <input type="number" #t class="form-control text-center" (change)="updateThresholds('T', +t.value, 0)" (blur)="onBlur('T')" name="T" [value]="getValue('T')" />
                    <div class="input-group-append">
                        <div class="input-group-text" (click)="add('T')">+</div>
                    </div>
                </div>
                <span class="mt-1">(characters)</span>
            </div>
            <div class="form-group col-3 text-center">
                <label for="sts">Text To Speech</label>
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text" [class.disabled]="disableQuotaInput('STS')" (click)="subtract('STS')">-</div>
                    </div>
                    <input type="number" #sts class="form-control text-center" (change)="updateThresholds('STS', +t.value, 0)" (blur)="onBlur('STS')" name="STS" [value]="getValue('STS')" />
                    <div class="input-group-append">
                        <div class="input-group-text" (click)="add('STS')">+</div>
                    </div>
                </div>
                <span class="mt-1">(requests)</span>
            </div>
        </div>
    </div>
    <div class="panel_wrapper cascade_wrap">
        <div class="form-row mb-4">
            <div class="col-12">Default Email Alert</div>
        </div>
        <div class="form-row">
            <div class="form-group col-3">
                <label for="notifiedUser">Email*</label>
                <input name="notifiedUser" type="text" id="notifiedUser" class="form-control" required [(ngModel)]="quotasData.email" #notifiedUser="ngModel" [ngClass]="{'is-invalid': notifiedUser.invalid && form.submitted}">
                <div class="invalid-feedback" *ngIf="notifiedUser?.errors?.required">Email is required</div>
            </div>
        </div>
    </div>
</form>
