import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AccountService } from 'src/app/services/account.service';
import { IQuotas, IThreshold } from './quotas.model';

@Component({
  selector: 'quotas',
  templateUrl: './quotas.component.html',
  styleUrls: ['./quotas.component.scss'],
})
export class QuotasComponent implements OnInit, OnDestroy {
  @Input() mode: 'CREATE' | 'EDIT' = 'CREATE';
  @Input() set account(value: any) {
    if (!this.accountValue) {
      this.accountValue = value;
    } else if (this.accountValue.id !== value.id) {
      this.accountValue = value;
      this.refreshQuotas();
    }
  }

  accountValue: any;
  subscriptions: any = {};

  quotasData = { thresholds: [] } as IQuotas;

  constructor(private accountService: AccountService) {}

  ngOnInit(): void {
    this.refreshQuotas();
  }

  refreshQuotas() {
    this.subscriptions['Quotas'] = this.accountService
      .getQuotas(this.accountValue.id)
      .pipe(
        catchError((e) => {
          if (e.status === 404) {
            this.mode = 'CREATE';
            return of({ accountId: this.accountValue.id, thresholds: [] });
          }
        })
      )
      .subscribe((data: IQuotas) => {
        this.quotasData = data;
      });
  }

  doSubmit() {
    this.quotasData.startDate = this.accountValue.startDate;
    this.quotasData.expiryDate = this.accountValue.endDate;
    if (this.mode === 'CREATE') {
      this.subscriptions['createQuotas'] = this.accountService.createQuotas(this.quotasData).subscribe((data: IQuotas) => {
        this.quotasData = data;
      });
    } else {
      this.subscriptions['updateQuotas'] = this.accountService.updateQuotas(this.quotasData).subscribe((data: IQuotas) => {
        this.quotasData = data;
      });
    }
  }

  createThresholds(serviceName: string, totalQuota: number, totalInteraction: number) {
    const newThresholds: IThreshold = {
      serviceName,
      totalQuota,
      totalInteraction,
    };
    this.quotasData.thresholds.push(newThresholds);
  }

  updateThresholds(serviceName: string, totalQuota: number, totalInteraction: number) {
    const thresholds = this.quotasData.thresholds.find((t) => t.serviceName === serviceName);
    if (thresholds) {
      thresholds.totalQuota = totalQuota;
      thresholds.totalInteraction = totalInteraction;
    } else {
      this.createThresholds(serviceName, totalQuota, totalInteraction);
    }
  }

  disableQuotaInput(serviceName: string) {
    if (this.quotasData && this.quotasData.thresholds) {
      const thresholdsData = this.quotasData.thresholds?.find((t) => t.serviceName === serviceName);
      if (!thresholdsData || !thresholdsData.totalQuota || thresholdsData.totalQuota <= 1000) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getValue(serviceName: string) {
    if (this.quotasData && this.quotasData.thresholds) {
      return this.quotasData.thresholds?.find((t) => t.serviceName === serviceName)?.totalQuota;
    }
  }

  subtract(serviceName: string) {
    const thresholdsData = this.quotasData.thresholds.find((t) => t.serviceName === serviceName);
    if (thresholdsData.totalQuota - 10 < 1000) {
      thresholdsData.totalQuota = 1000;
    } else {
      thresholdsData.totalQuota = thresholdsData.totalQuota - 10;
    }
  }

  add(serviceName: string) {
    const thresholdsData = this.quotasData.thresholds.find((t) => t.serviceName === serviceName);
    if (thresholdsData) {
      thresholdsData.totalQuota = thresholdsData.totalQuota + 10;
    } else {
      this.createThresholds(serviceName, 1000, 0);
    }
  }

  onBlur(serviceName: string) {
    const thresholdsData = this.quotasData.thresholds.find((t) => t.serviceName === serviceName);
    if (thresholdsData.totalQuota != null && thresholdsData.totalQuota < 1000) {
      thresholdsData.totalQuota = 1000;
    }
  }

  isConnectorPurchased(code) {
    const connector = this.accountValue.products.Connector.find((el) => el.code === code);
    return connector?.purchased;
  }

  canQuote() {
    return this.isConnectorPurchased('DF') || this.isConnectorPurchased('NL') || this.isConnectorPurchased('DLP');
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key) => {
      this.subscriptions[key].unsubscribe();
    });
  }
}
