<div class="container_wrapper" [spinnerLoader]="subscriptions['getCustomers']?.closed">
    <div class="header_wrapper sticky">
        <div class="row justify-content-between">
            <div class="col-auto my-auto">
                <span>Customers</span>
            </div>
            <div class="col-auto my-auto">
                <button class="btn brand_transparent mr-3" *ngIf="customerSelected" (click)="goToNewAccount()">New Account</button>
                <button class="btn brand_default" (click)="goToNewCusotomer()">New Customer</button>
            </div>
        </div>
    </div>
    <div class="panel_wrapper">
        <div class="row">
            <div class="p-0" [ngClass]="(customerSelected && customerSelected.accounts?.length > 0) ? 'col-8' : 'col-12'">
                <div class="d-flex item p-0" [ngClass]="{'selected': customer.selected}" *ngFor="let customer of customers" (click)="selectCutomer(customer)">
                    <div class="flex-grow-1 px-4 customer-name custom-padding">
                        {{customer.name}}
                    </div>
                    <div class="px-4 custom-padding gray-text">
                        {{(customer.accounts?.length || 0)}} account{{customer.accounts?.length > 0 ? 's' : ''}}
                    </div>
                    <div class="align-middle text-center delete" (click)="deleteCustomer(customer); $event.stopPropagation()" *ngIf="!customerSelected && customer.accounts?.length === 0">
                        <i class="fas fa-trash black-icon custom-padding" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="col-4" *ngIf="customerSelected && customerSelected.accounts?.length > 0">
                <div class="row pl-4 justify-content-between item p-0" *ngFor="let account of customerSelected.accounts" (click)="editAccount(account)">
                    <div class="col-auto customer-name custom-padding">
                        {{account.name}}
                    </div>
                    <div class="col-auto align-middle text-center delete" (click)="deleteAccount(account); $event.stopPropagation()">
                        <i class="fas fa-trash black-icon custom-padding" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

