import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'dialogflow-agents',
  templateUrl: './dialogflow-agents.component.html',
  styleUrls: ['./dialogflow-agents.component.scss']
})
export class DialogflowAgentsComponent implements OnInit, OnDestroy {

  @Input() mode : 'CREATE' | 'EDIT' = 'CREATE'
  @Input() set account (value: any) {
    if (!this.accountValue) {
      this.accountValue = value;
    } else if (this.accountValue.id !== value.id) {
      this.accountValue = value;
      this.refreshData();

    }
  }

  accountValue: any;
  subscriptions: any = {};

  agentData = [];
  agentSelection;
  agentTypes: string[] = [];

  constructor(private accountService: AccountService) { }

  ngOnInit(): void {
    if(this.mode === 'EDIT') {
      this.refreshData();
    } else {
      this.initAgents();
    }
    this.accountService.getAgentTypes().subscribe((agentTypes: string[]) => {
      this.agentTypes = agentTypes;
    })
  }

  refreshData() {
    this.subscriptions['getAgents'] = this.accountService.getAgents(this.accountValue.id).subscribe((data: any[]) => {
      this.agentData = data;
      if (this.agentData.length > 0) {
        this.agentSelection = this.agentData[0]
      } else {
        this.initAgents();
      }
    })
  }

  addAgent() {
    const newAgent = {name: `Virtual Agent ${this.agentData.length + 1}`}
    this.agentData.push(newAgent);
    this.agentSelection = newAgent;
  }

  deleteAgent(id, index) {
    if (id != null) {
      this.subscriptions['deleteAgent'] = this.accountService.deleteAgent(id).subscribe(() => {
        this.removeAgentFromList(index);
      })
    } else {
      this.removeAgentFromList(index);
    }
  }

  doSubmit(form: FormGroup) {
    if (form.valid) {
      this.subscriptions['updateAgents'] = this.accountService.updateAgents(this.accountValue.id, this.agentData).subscribe((data: any[]) => {
        this.agentData = data;
        if (this.agentData.length > 0) {
          this.agentSelection = this.agentData[0]
        } else {
          this.initAgents();
        }
      });
    } else {
      const keyFirstInvalidObject = Object.keys(form.controls).find(key => form.controls[key].invalid)
      if (keyFirstInvalidObject) {
        const indexAgentFail = keyFirstInvalidObject[0];
        this.agentSelection = this.agentData[indexAgentFail];
      }
    }
  }

  initAgents() {
    this.agentData.push({name: 'Virtual Agent 1'})
    this.agentData.push({name: 'Virtual Agent 2'})
    this.agentSelection = this.agentData[0];
  }

  removeAgentFromList(index) {
    this.agentData.splice(index, 1);
    if (this.agentData.length > 0) {
      this.agentSelection = this.agentData[0]
    } else {
      this.agentData.push({name: 'Virtual Agent 1'});
      this.agentSelection = this.agentData[0];
    }
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach(key => {
      this.subscriptions[key].unsubscribe();
    })
  }

}
