import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from 'src/app/components/modals/confirmation-modal/confirmation-modal.component';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'administrator-users',
  templateUrl: './administrator-users.component.html',
  styleUrls: ['./administrator-users.component.scss']
})
export class AdministratorUsersComponent implements OnInit, OnDestroy {

  @Input() mode: 'CREATE' | 'EDIT' = 'CREATE'
  @Input() set account(value: any) {
    if (!this.accountValue) {
      this.accountValue = value;
    } else if (this.accountValue.id !== value.id) {
      this.accountValue = value;
      this.refreshData();

    }
  }

  accountValue: any;
  subscriptions: any = {};

  users = [];

  constructor(private accountService: AccountService, private modalService: NgbModal) { }

  ngOnInit(): void {
    if (this.mode === 'EDIT') {
      this.refreshData();
    } else {
      this.addUser();
    }
  }

  refreshData() {
    this.subscriptions['getUsers'] = this.accountService.getUsers(this.accountValue.id).subscribe((data: any) => {
      this.users = data;
      if (this.users.length === 0) {
        this.addUser();
      }
    })
  }

  addUser() {
    this.users.push({})
  }

  doSubmit() {
    this.subscriptions['updateUsers'] = this.accountService.updateUsers(this.accountValue.id, this.users).subscribe((data: any) => {
      this.users = data;
      if (this.users.length === 0) {
        this.addUser();
      }
    })
  }

  deleteUser(index) {
    if (this.users[index].id) {
      // open confirm modal
      const modal = this.modalService.open(ConfirmationModalComponent, { size: 'lg', centered: true });
      modal.componentInstance.title = 'Administrator user delete';
      modal.componentInstance.type = 'user';
      modal.componentInstance.elementId = this.users[index].id;
      modal.componentInstance.message = `Confirm delete admin-user ${this.users[index].email} ?`;
      modal.closed.subscribe((userId) => {
        // Do Delete
        if (userId) {
          this.accountService.deleteUser(this.accountValue.id, userId).subscribe(() => this.refreshData());
        }
      });
    } else {
      // delete from list
      this.users.splice(index, 1);
    }
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach(key => {
      this.subscriptions[key].unsubscribe();
    })
  }

}
