import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CustomersComponent } from './components/customers/customers.component';
import { AccountSettingsComponent } from './components/account-settings/account-settings.component';
import { CustomerSettingsComponent } from './components/customer-settings/customer-settings.component';

const routes: Routes = [
  { path: '', redirectTo: '/customers', pathMatch: 'full' },
  // { path: 'customers', component: customersComponent },
  // { path: 'customer-settings', component: customerSettingsComponent },

  // { path: 'customer/:idCustomer/account-settings', component: component: accountSettingsComponent }
  // { path: 'customer/:idCustomer/account-settings/:idAccount', component: accountSettingsComponent }
  {
    path: 'customers',
    component: CustomersComponent
  },
  {
    path: 'customer-settings',
    component: CustomerSettingsComponent
  },
  { path: 'customer/:idCustomer/account-settings', component: AccountSettingsComponent },
  { path: 'customer/:idCustomer/account-settings/:idAccount', component: AccountSettingsComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

/*
  CUSTOMERS   GET/POST
  ACCOUNTS    GET/POST/PUT  (ID_CUSTOMER)

  QUOTE       GET/POST/PUT  (ID_ACCOUNT)
  AGENTS      GET/POST/PUT  (ID_ACCOUNT - INTERNO ALL'ITEM)
  USER        GET/POST/PUT  (ID_ACCOUNT - INTERNO ALL'ITEM)


  
  configurator/api/v2/admin/products/connectors {
    connector: [
      {
        id:
        name:
        purchase: ??
        category: 'Google Cloud API'
      }
    ]
  }
  agentbot/idAccount, in post no id agent
  in put con id

  planning/billingasccountId  sts ignorare, passiamo anche le date dalla licenza, lastTotalUpdate non serve, account id non serve
*/
