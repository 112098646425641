
<div class="console_modal notification_modal">
    <div class="modal-header" *ngIf="options.type !== 'spinner'">
        <h4 class="modal-title">{{options.title}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="confirm()" *ngIf="options.choice !== 'multi'">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="body_content" *ngIf="options.type !== 'spinner'">
            <div class="row">
                <div class="col-2 content_type">
                    <i class="fal fa-info-circle fa-4x text-primary" aria-hidden="true" *ngIf="options.type === 'info'"></i>
                    <i class="fal fa-times-circle fa-4x text-danger" aria-hidden="true" *ngIf="options.type === 'danger'"></i>
                    <i class="fal fa-exclamation-triangle fa-4x text-warning" aria-hidden="true" *ngIf="options.type === 'warning' || options.choice === 'multi'"></i>
                </div>

                <div class="col-10">
                    <article class="content_message" [innerHtml]="options.message"></article>

                    <article class="service_error" *ngIf="options.serviceError">
                        <header class="error_head" (click)="errorCollapsed = !errorCollapsed">
                            {{(errorCollapsed ? 'Show' : 'Hide')}} error details
                            <button type="button" class="btn p-0" [attr.aria-expanded]="!errorCollapsed" aria-controls="collapseExample">
                                <i class="fas fa-angle-up" aria-hidden="true" *ngIf="!errorCollapsed"></i>
                                <i class="fas fa-angle-down" aria-hidden="true" *ngIf="errorCollapsed"></i>
                            </button>
                        </header>
                        <div class="alert alert-danger error_details" role="alert" #collapse="ngbCollapse" [(ngbCollapse)]="errorCollapsed">
                            <span class="d-block mb-1">{{options.serviceError.code}} service error</span>
                            {{options.serviceError.message}}
                        </div>
                    </article>
                </div>
            </div>
        </div>

        <article class="spinner_content" *ngIf="options.type === 'spinner'">
            <h5 class="mb-2">Loading...</h5>
            <div class="loading_box">
                <ng-template [spinnerLoader]="false" size="2x"></ng-template>
            </div>
        </article>
    </div>
    <div class="modal-footer" *ngIf="options.type !== 'spinner'" [ngClass]="{'justify-content-center': options.choice === 'single'}">
        <button type="button" class="btn brand_light" (click)="abort()" *ngIf="options.choice === 'multi'">Cancel</button>
        <button type="button" class="btn brand_default" (click)="confirm()" *ngIf="options.choice !== 'support'">OK</button>
    </div>
</div>
