<form name="account" #form="ngForm" class="form_container needs-validation" (submit)="form.valid && doSubmit()" autocomplete="off">
    <ng-template [spinnerLoader]="subscriptions['updateAccount']?.closed"></ng-template>
    <ng-template [spinnerLoader]="subscriptions['createAccount']?.closed"></ng-template>
    <div class="header_wrapper sticky">
        <div class="row justify-content-between">
            <div class="col-auto my-auto">
                <span>{{ title }}</span>
            </div>
            <div class="col-auto my-auto">
                <button class="btn brand_default">Save</button>
            </div>
        </div>
    </div>
    <div class="panel_wrapper cascade_wrap">
        <div class="form-row">
            <div class="form-group col-3">
                <label for="accountName">Account name*</label>
                <input type="text" id="accountName" class="form-control" name="name" maxlength="200" [(ngModel)]="accountValue.name" required #accountName="ngModel" [ngClass]="{ 'is-invalid': accountName?.invalid && form.submitted }" />
                <div class="invalid-feedback" *ngIf="accountName?.errors?.required">Account name is required</div>
            </div>
            <div class="form-group col-3">
                <label for="accountCode">Account code*</label>
                <input type="text" id="accountCode" class="form-control" name="code" maxlength="50" [(ngModel)]="accountValue.code" required #accountCode="ngModel" [ngClass]="{ 'is-invalid': accountCode?.invalid && form.submitted }" />
                <div class="invalid-feedback" *ngIf="accountCode?.errors?.required">Account code is required</div>
            </div>
            <div class="form-group col-3">
                <label for="licenseStart">License Start date*</label>
                {{ licenseStartModel?.errors?.temporaryValidator }}
                <div class="input-group custom-datepicker">
                    <input
                        type="text"
                        id="licenseStart"
                        class="form-control"
                        ngbDatepicker
                        #licenseStart="ngbDatepicker"
                        name="startDate"
                        [(ngModel)]="startDate"
                        temporaryValidator
                        [before]="endDate"
                        readonly
                        placeholder="dd/mm/yyyy"
                        required
                        #licenseStartModel="ngModel"
                        [ngClass]="{ 'is-invalid': licenseStartModel?.invalid && form.submitted }"
                    />
                    <span class="fal fa-calendar" (click)="licenseStart.toggle()"></span>
                    <div class="invalid-feedback" *ngIf="licenseStartModel?.errors?.required">License Start date is required</div>
                    <div class="invalid-feedback" *ngIf="licenseStartModel?.errors?.temporaryValidatorBefore">License Start date is after End date</div>
                </div>
            </div>
            <div class="form-group col-3">
                <label for="licenseEnd">License End date*</label>
                <div class="input-group custom-datepicker">
                    <input
                        type="text"
                        id="licenseEnd"
                        class="form-control"
                        ngbDatepicker
                        #licenseEnd="ngbDatepicker"
                        name="endDate"
                        [(ngModel)]="endDate"
                        temporaryValidator
                        [after]="startDate"
                        readonly
                        placeholder="dd/mm/yyyy"
                        required
                        #licenseEndModel="ngModel"
                        [ngClass]="{ 'is-invalid': licenseEndModel?.invalid && form.submitted }"
                    />
                    <span class="fal fa-calendar" (click)="licenseEnd.toggle()"></span>
                    <div class="invalid-feedback" *ngIf="licenseEndModel?.errors?.required">License End date is required</div>
                    <div class="invalid-feedback" *ngIf="licenseEndModel?.errors?.temporaryValidatorAfter">License End date is before Start date</div>
                </div>
            </div>
        </div>
    </div>
    <div class="panel_wrapper cascade_wrap">
        <fieldset>
            <legend>Google Cloud Project</legend>

            <div class="form-row mt-3">
                <div class="form-group col-3">
                    <div class="form-row">
                        <div class="form-group col">
                            <label for="gcs_projectId">Project ID*</label>
                            <input type="text" id="gcs_projectId" class="form-control" name="projectId" [(ngModel)]="accountValue.projectId" required #projectId="ngModel" [ngClass]="{ 'is-invalid': projectId?.invalid && form.submitted }" />
                            <div class="invalid-feedback" *ngIf="projectId?.errors?.required">Project ID is required</div>
                        </div>
                    </div>
                </div>
                <div class="form-group col-4" *ngIf="MODE === 'CREATE'; else editServiceAccount">
                    <label for="serviceAccount">Service Account*</label>
                    <textarea rows="10" id="serviceAccount" class="form-control" name="serviceAccount" [(ngModel)]="accountValue.serviceAccount" required #serviceAccount="ngModel" [ngClass]="{ 'is-invalid': serviceAccount?.invalid && form.submitted }"></textarea>
                    <div class="invalid-feedback" *ngIf="serviceAccount?.errors?.required">Service Account is required</div>
                </div>
                <ng-template #editServiceAccount>
                    <div class="form-group col-4">
                        <label for="serviceAccount">Service Account* <a class="ml-2" href="javascript:void(0)" (click)="updateServiceAccount()">Update</a></label>
                        <div class="text-success"><i class="far fa-check-circle fa-sm" aria-hidden="true"></i> {{ serviceAccountLabel }}</div>
                    </div>
                </ng-template>
            </div>
        </fieldset>
    </div>
    <div class="panel_wrapper cascade_wrap">
        <fieldset>
            <legend>Journey</legend>

            <div class="form-row mt-3">
                <div class="form-group col-2">
                    <label for="journeyLimit">Journey limit</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <div class="input-group-text" [class.disabled]="!accountValue.journeyLimit || accountValue.journeyLimit <= 1" (click)="subtractJourneyLimit()">-</div>
                        </div>
                        <input type="number" id="journeyLimit" class="form-control text-center" [(ngModel)]="accountValue.journeyLimit" (blur)="onBlurJourneyLimit()" name="journeyLimit" />
                        <div class="input-group-append">
                            <div class="input-group-text" (click)="addJourneyLimit()">+</div>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
    </div>
    <div class="panel_wrapper cascade_wrap">
        <legend>Connectors</legend>

        <div class="form-row pt-4 pb-4" *ngFor="let category of connectorCategories">
            <fieldset class="fieldset p-2">
                <legend class="legend">{{ category }}</legend>
                <ng-container *ngFor="let connector of accountValue.products.Connector; let index = index">
                    <div class="custom-control custom-checkbox custom-control-inline" *ngIf="connector.category === category">
                        <input type="checkbox" class="custom-control-input" [name]="'connector_' + connector.id" [(ngModel)]="connector.purchased" [id]="'connector_' + connector.id" />
                        <label class="custom-control-label" [for]="'connector_' + connector.id">{{ connector.name }}</label>
                    </div>
                </ng-container>
            </fieldset>
        </div>
    </div>
    <div class="panel_wrapper cascade_wrap">
        <legend>Channels</legend>

        <div class="form-row pt-4 pb-4" *ngFor="let category of channelCategories">
            <fieldset class="fieldset p-2">
                <legend class="legend">{{ category }}</legend>
                <ng-container *ngFor="let channel of accountValue.products.Channel; let index = index">
                    <div class="custom-control custom-checkbox custom-control-inline" *ngIf="channel.category === category">
                        <input type="checkbox" class="custom-control-input" [name]="'channel_' + channel.id" [(ngModel)]="channel.purchased" [id]="'channel_' + channel.id" />
                        <label class="custom-control-label" [for]="'channel_' + channel.id">{{ channel.name }}</label>
                    </div>
                </ng-container>
            </fieldset>
        </div>
    </div>
    <div class="panel_wrapper cascade_wrap">
        <legend>Addon modules</legend>

        <div class="form-row pt-4 pb-5">
            <div class="form-group col-12">
                <ng-container *ngFor="let module of accountValue.products?.Module; let index = index">
                    <div class="custom-control custom-checkbox custom-control-inline" *ngIf="module.code !== 'M_C'">
                        <input type="checkbox" class="custom-control-input" [name]="'module_' + module.id" [(ngModel)]="module.purchased" [id]="'module_' + module.id" />
                        <label class="custom-control-label" [for]="'module_' + module.id">{{ module.name }}</label>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="panel_wrapper cascade_wrap">
        <legend>Conversation templates</legend>
        <div class="form-row pt-4 pb-5">
            <div class="form-group col-12" *ngIf="accountValue.products">
                <ng-container *ngFor="let template of accountValue.products['Conversational model']; let index = index">
                    <div class="custom-control custom-checkbox custom-control-inline">
                        <input type="checkbox" class="custom-control-input" [name]="'template_' + template.id" [(ngModel)]="template.purchased" [id]="'template_' + template.id" />
                        <label class="custom-control-label" [for]="'template_' + template.id">{{ template.name }}</label>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</form>
