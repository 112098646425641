<form #form="ngForm" name="customerForm" class="form_container" (submit)="form.valid && doSubmit()" autocomplete="off">
    <ng-template [spinnerLoader]="subscriptions['createCustomer']?.closed"></ng-template>
    <div class="container_wrapper sticky">
        <div class="breadcrumb_wrapper pb-4">
            <span (click)="goToCustomer()">Customers ></span><span class="actual"> New customer</span>
        </div>
        <div class="header_wrapper">
            <div class="row justify-content-between">
                <div class="col-auto my-auto">
                    <span>Customer</span>
                </div>
                <div class="col-auto my-auto">
                    <button class="btn brand_default">Save</button>
                </div>
            </div>
        </div>
        <div class="panel_wrapper">
            <div class="form-row">
                <div class="form-group col-4">
                    <label>Customer name*</label>
                    <input type="text" class="form-control" name="customerName" maxlength="200" [(ngModel)]="customerName" required #customerNameModel="ngModel" [ngClass]="{'is-invalid': customerNameModel?.invalid && form.submitted}" />
                    <div class="invalid-feedback" *ngIf="customerNameModel?.errors?.required">Customer name is required</div>
                </div>
                <div class="form-group col-4">
                    <label for="customerDescription">Description</label>
                    <textarea rows="10" id="customerDescription" class="form-control" name="customerDescription" [(ngModel)]="customerDescription" maxlength="300"></textarea>
                </div>
            </div>
        </div>
    </div>
</form>
