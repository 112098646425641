<form name="quotas" #form="ngForm" class="form_container" (submit)="form.valid && doSubmit()">
    <ng-template [spinnerLoader]="subscriptions['createQuotas']?.closed"></ng-template>
    <ng-template [spinnerLoader]="subscriptions['updateQuotas']?.closed"></ng-template>
    <div class="header_wrapper sticky">
        <div class="row justify-content-between">
            <div class="col-auto my-auto">
                <span>Files Storage</span>
            </div>
            <div class="col-auto my-auto">
                <button class="btn brand_default">Save</button>
            </div>
        </div>
    </div>
    <div class="panel_wrapper">
        <div class="form-row mb-3">
            <div class="col-12">
                <div class="custom-control custom-radio custom-control-inline">
                    <input name="none" type="radio" id="none" class="custom-control-input" [(ngModel)]="storageData.type" [value]="TYPES.NONE">
                    <label class="custom-control-label" for="none" >None</label>
                </div>
                <div class="custom-control custom-radio custom-control-inline">
                    <input name="cloudStorage" type="radio" id="cloudStorage" class="custom-control-input" [(ngModel)]="storageData.type" [value]="TYPES.CLOUD_STORAGE">
                    <label class="custom-control-label" for="cloudStorage" >Google Cloud Storage</label>
                </div>
            </div>
        </div>
        <ng-container [ngSwitch]="storageData.type">
            <div *ngSwitchCase="TYPES.CLOUD_STORAGE" class="form-row">
                <div class="form-group col-4">
                    <label for="bucketName">Buket Name*</label>
                    <input type="text" id="bucketName" class="form-control" [(ngModel)]="configuration.name" name="bucketName" required [ngClass]="{ 'is-invalid': form.form.controls['bucketName']?.invalid && form.submitted }" />
                    <div class="invalid-feedback" *ngIf="form.form.controls['bucketName']?.errors?.required">Bucket Name is required</div>
                </div>
            </div>
        </ng-container>
    </div>
</form>
