import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { sortListByName } from 'src/app/functions/general';
import { AccountService } from 'src/app/services/account.service';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements OnInit, OnDestroy {

  customer;
  account;
  actualIndexAccount = 0;

  PAGE_MODE: 'CREATE' | 'EDIT' = 'CREATE';

  subscriptions = {};

  constructor(private activateRoute: ActivatedRoute, private router: Router, private customerService: CustomerService, private accountService: AccountService) { }

  ngOnInit(): void {
    this.subscriptions['router'] = this.activateRoute.params.subscribe(param => {
      this.PAGE_MODE = param.idAccount ? 'EDIT' : 'CREATE';

      if (this.PAGE_MODE === 'CREATE') {
        this.getCustomer(param.idCustomer);
      } else if (!this.customer) {
        this.getCustomerAndAccount(param.idCustomer, param.idAccount); // Consistency check: retrieve account only if customer exists
      } else if (!this.account || this.account.id != param.idAccount) {
        this.getAccount(param.idAccount)
      }
    });
  }

  goToCustomer() {
    this.router.navigate(['customers']);
  }

  accountSubmitted(account) {
    if (!this.account) {
      this.customer.accounts.push( {...account});
      this.actualIndexAccount = this.customer.accounts.length - 1;
    } else {
      this.customer.accounts[this.actualIndexAccount] = {...account};
    }
    this.account = account;
  }

  getCustomer(idCustomer) {
    this.subscriptions['getCustomer'] = this.customerService.getCustomer(idCustomer).subscribe(data => {
      this.customer = data;
      sortListByName(this.customer.accounts);
    })
  }

  getAccount(idAccount) {
    this.subscriptions['getAccount'] = this.accountService.getAccount(idAccount).subscribe(data => {
      this.account = data;
      if (this.customer?.accounts?.length > 0) {
        this.actualIndexAccount = this.customer.accounts.findIndex(acc => this.account.id === acc.id);
      }
    })
  }

  getCustomerAndAccount(idCustomer, idAccount) {
    this.subscriptions['getCustomer'] = this.customerService.getCustomer(idCustomer).subscribe(data => {
      this.customer = data;
      sortListByName(this.customer.accounts);
      this.getAccount(idAccount);
    })
  }

  prevUser() {
    if (this.actualIndexAccount > 0) {
      this.router.navigate([`customer/${this.customer.id}/account-settings/${this.customer.accounts[this.actualIndexAccount - 1].id}`]);
    }
  }

  nextUser() {
    if (this.actualIndexAccount < this.customer.accounts.length - 1) {
      this.router.navigate([`customer/${this.customer.id}/account-settings/${this.customer.accounts[this.actualIndexAccount + 1].id}`]);
    }
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach(key => {
      this.subscriptions[key].unsubscribe();
    })
  }

}
