<div class="container_wrapper" [spinnerLoader]="(!subscriptions['getCustomer'] || subscriptions['getCustomer'].closed) && (!subscriptions['getAccount'] || subscriptions['getAccount'].closed)">
    <div class="row justify-content-between pb-4">
        <div class="breadcrumb_wrapper col-auto">
            <ng-container *ngIf="PAGE_MODE === 'CREATE'"><span (click)="goToCustomer()">Customers > {{customer?.name}} ></span><span class="actual"> New account</span></ng-container>
            <ng-container *ngIf="PAGE_MODE === 'EDIT'"><span  (click)="goToCustomer()">Customers > {{customer?.name}} ></span><span class="actual"> {{account?.name}}</span></ng-container>
        </div>
        <div class="col-auto responses_sidebar" *ngIf="PAGE_MODE === 'EDIT' && account && customer?.accounts?.length > 0">
            <div class="sidebar_pagination">
                <button type="button" class="btn pagination_nav" *ngIf="actualIndexAccount > 0" (click)="prevUser()"><i class="fas fa-chevron-left" aria-hidden="true"></i></button>
                <span class="pagination_pager">{{account.name}}</span>
                <button type="button" class="btn pagination_nav" *ngIf="actualIndexAccount < customer.accounts.length - 1" (click)="nextUser()"><i class="fas fa-chevron-right" aria-hidden="true"></i></button>
            </div>
        </div>
    </div>
    <account-base-form
        (submitted)="accountSubmitted($event)"
        [title]="PAGE_MODE === 'CREATE' ? 'New Account details' : 'Account details'"
        *ngIf="PAGE_MODE === 'CREATE' || account"
        [account]="account"
        [customerId]="customer?.id"
    ></account-base-form>
    <ng-container *ngIf="account">
        <quotas [mode]="PAGE_MODE" [account]="account"></quotas>
        <files-storage [account]="account"></files-storage>
        <dialogflow-agents [mode]="PAGE_MODE" [account]="account"></dialogflow-agents>
        <administrator-users [mode]="PAGE_MODE" [account]="account"></administrator-users>
    </ng-container>
</div>
