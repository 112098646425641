import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(private http: HttpClient) {}

  getCustomers() {
    return this.http.get(`${environment.API.orchestrator_configurator}/customers`);
  }

  getCustomer(idCustomer: number) {
    return this.http.get(`${environment.API.orchestrator_configurator}/customers/${idCustomer}`);
  }

  createCustomer(data: any) {
    return this.http.post(`${environment.API.orchestrator_configurator}/customers`, data);
  }

  deleteCustomer(customerId: number) {
    const httpOptions = {
      params: new HttpParams().set('customerId', customerId.toString()),
    };
    return this.http.delete(`${environment.API.orchestrator_configurator}/customers`, httpOptions);
  }
}
