import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { OAuthModule } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { AuthenticationInterceptor } from './http-interceptors/authentication-interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
export function initAuth(authService: AuthService) {
  return () => authService.initAuth();
}
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AuthRoutingModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [`${environment.API.orchestrator_configurator}`],
        sendAccessToken: true,
      },
    }),
  ],
  providers: [AuthService, { provide: APP_INITIALIZER, useFactory: initAuth, deps: [AuthService], multi: true }, { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true }],
})
export class AuthModule {}
