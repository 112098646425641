import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Directive({
  selector: '[temporaryValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: TemporaryValidatorDirective,
      multi: true,
    },
  ],
})
export class TemporaryValidatorDirective implements Validator {
  @Input() after: NgbDate;
  @Input() before: NgbDate;

  validate(control: AbstractControl): { [key: string]: any } | null {
    const date = control.value as NgbDate;
    if (date && this.before) {
      if (this.isAfter(date, this.before)) {
        return { temporaryValidatorBefore: true };
      }
    }
    if (date && this.after) {
      if (this.isAfter(this.after, date)) {
        return { temporaryValidatorAfter: true };
      }
    }
    return null;
  }

  private isAfter(date1: NgbDate, date2: NgbDate) {
    if (date2.year > date1.year || (date2.year === date1.year && date2.month > date1.month) || (date2.year === date1.year && date2.month === date1.month && date2.day > date1.day)) {
      return false;
    }
    return true;
  }
}
