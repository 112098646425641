<div class="confirmation_modal">
    <div class="modal-header align-items-center">
        <span class="modal-title">{{ title }}</span>
        <button type="button" class="close" aria-label="Close" (click)="abort()">
            <i class="fal fa-times" style="color: white;" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="body_content d-flex align-content-around flex-wrap pt-3">
            <i class="far fa-exclamation-triangle fa-2x pr-3" style="color: #FFB92E;" aria-hidden="true"></i>
            <p class="pt-1">{{ message }}</p>
        </div>
    </div>
    <div class="modal-footer border-0">
        <button type="button" class="btn brand_default" (click)="confirm(elementId)">Delete {{ type }}</button>
    </div>
</div>