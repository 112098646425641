import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceAccountModalComponent } from 'src/app/components/modals/service-account-modal/service-account-modal.component';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'account-base-form',
  templateUrl: './account-base-form.component.html',
  styleUrls: ['./account-base-form.component.scss'],
})
export class AccountBaseFormComponent implements OnInit {
  @Input() title = '';
  @Input() set account(value: any) {
    if (value && value !== this.accountValue) {
      this.MODE = 'EDIT';
      this.accountValue = value;
      this.setNgbDate(this.accountValue.startDate, 'startDate');
      this.setNgbDate(this.accountValue.endDate, 'endDate');
      this.getConnectorCategories(this.accountValue.products.Connector);
      this.getChannelCategories(this.accountValue.products.Channel);
    }
  }
  @Input() customerId;
  @Output() submitted = new EventEmitter<any>();

  accountValue: any = {};
  MODE: 'CREATE' | 'EDIT' = 'CREATE';
  subscriptions: any = {};

  startDate: any;
  endDate: any;
  connectorCategories = [];
  channelCategories = [];
  serviceAccountLabel = 'Service account already set up';
  constructor(private accountService: AccountService, private modalService: NgbModal) {}

  ngOnInit(): void {
    if (this.MODE === 'CREATE') {
      this.subscriptions['getProducts'] = this.accountService.getProducts().subscribe((products: any) => {
        this.accountValue.products = products;
        this.accountValue.products.Connector.forEach((connector) => {
          if (connector.code === 'DF' || connector.category === 'System connectors') {
            connector.purchased = true;
          }
        });
        this.getConnectorCategories(this.accountValue.products.Connector);
        this.getChannelCategories(this.accountValue.products.Channel);
        const coreModule = this.accountValue.products.Module.find((el) => el.code === 'M_C');
        if (coreModule) {
          coreModule.purchased = true;
        }
      });
    }
  }

  doSubmit() {
    this.setDate(this.startDate, 'startDate');
    this.setDate(this.endDate, 'endDate');
    if (this.MODE === 'EDIT') {
      this.subscriptions['updateAccount'] = this.accountService.updateAccount(this.accountValue).subscribe((data) => {
        this.account = data;
        this.submitted.emit(this.accountValue);
      });
    } else {
      this.accountValue.customerId = this.customerId;
      this.subscriptions['createAccount'] = this.accountService.createAccount(this.accountValue).subscribe((data) => {
        this.account = data;
        this.submitted.emit(this.accountValue);
      });
    }
  }

  setNgbDate(date: number, dateName: string) {
    if (date) {
      const dateFormatted = new Date(date);
      this[dateName] = { day: dateFormatted.getDate(), month: dateFormatted.getMonth() + 1, year: dateFormatted.getFullYear() };
    }
  }

  setDate(date: NgbDate, dateName: string) {
    if (date) {
      this.accountValue[dateName] = new Date(date.year, date.month - 1, date.day).getTime();
    }
  }

  getConnectorCategories(connectors) {
    this.connectorCategories = [];
    connectors.forEach((connector) => {
      if (!this.connectorCategories.find((category) => category === connector.category)) {
        this.connectorCategories.push(connector.category);
      }
    });
  }

  getChannelCategories(channels) {
    this.channelCategories = [];
    channels.forEach((channel) => {
      if (!this.channelCategories.find((category) => category === channel.category)) {
        this.channelCategories.push(channel.category);
      }
    });
  }

  addJourneyLimit() {
    if (this.accountValue.journeyLimit) {
      this.accountValue.journeyLimit++;
    } else {
      this.accountValue.journeyLimit = 1;
    }
  }

  subtractJourneyLimit() {
    if (this.accountValue.journeyLimit && this.accountValue.journeyLimit > 1) {
      this.accountValue.journeyLimit--;
    }
  }

  onBlurJourneyLimit() {
    if (this.accountValue.journeyLimit || this.accountValue.journeyLimit < 1) {
      this.accountValue.journeyLimit = 1;
    }
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  updateServiceAccount() {
    const modal = this.modalService.open(ServiceAccountModalComponent, {
      size: 'lg',
    });

    modal.componentInstance.accountId = this.accountValue.id;
    modal.closed.subscribe((updated) => {
      if (updated) {
        this.serviceAccountLabel = 'Service account updated';
      }
    });
  }
}
