import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomersComponent } from './components/customers/customers.component';
import localeIt from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
import { DateParserFormatter } from './classes/date-parser-formatter';
import { AccountBaseFormComponent } from './components/account-settings/account-fields/account-base-form/account-base-form.component';
import { QuotasComponent } from './components/account-settings/account-fields/quotas/quotas.component';
import { DialogflowAgentsComponent } from './components/account-settings/account-fields/dialogflow-agents/dialogflow-agents.component';
import { AdministratorUsersComponent } from './components/account-settings/account-fields/administrator-users/administrator-users.component';
import { CustomerSettingsComponent } from './components/customer-settings/customer-settings.component';
import { AccountSettingsComponent } from './components/account-settings/account-settings.component';
import { HttpClientModule } from '@angular/common/http';
import { SpinnerLoaderComponent } from './components/utility/spinner-loader/spinner-loader.component';
import { SpinnerLoaderDirective } from './directives/spinner-loader.directive';
import { httpInterceptorProviders } from './http-interceptors';
import { NotificationModalComponent } from './components/modals/notification-modal/notification-modal.component';
import { ServiceAccountModalComponent } from './components/modals/service-account-modal/service-account-modal.component';
import { FilesStorageComponent } from './components/account-settings/account-fields/files-storage/files-storage.component';
import { ConfirmationModalComponent } from './components/modals/confirmation-modal/confirmation-modal.component';
import { ConfirmDeleteModalComponent } from './components/modals/confirm-delete-modal/confirm-delete-modal.component';
import { AuthModule } from './modules/auth/auth.module';
import { TemporaryValidatorDirective } from './directives/temporary-validator.directive';

registerLocaleData(localeIt, 'it');
@NgModule({
  declarations: [
    AppComponent,
    CustomersComponent,
    CustomerSettingsComponent,
    AccountSettingsComponent,
    AccountBaseFormComponent,
    QuotasComponent,
    DialogflowAgentsComponent,
    AdministratorUsersComponent,
    SpinnerLoaderComponent,
    SpinnerLoaderDirective,
    NotificationModalComponent,
    ServiceAccountModalComponent,
    FilesStorageComponent,
    ConfirmationModalComponent,
    ConfirmDeleteModalComponent,
    TemporaryValidatorDirective,
  ],
  imports: [BrowserModule, HttpClientModule, FormsModule, ReactiveFormsModule, AppRoutingModule, NgbModule, AuthModule],
  providers: [httpInterceptorProviders, { provide: LOCALE_ID, useValue: 'it' }, { provide: NgbDateParserFormatter, useClass: DateParserFormatter }],
  bootstrap: [AppComponent],
})
export class AppModule {}
