<header id="console_head">
    <nav class="navbar">
        <a href="#" [routerLink]="['/']" class="tellya_logo"></a>
        <button (click)="authService.logout()" class="btn font-weight-bold">LOGOUT</button>
    </nav>
</header>

<div class="container-fluid">
    <router-outlet></router-outlet>
</div>
