import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {

  title: string;
  elementId: string | number;
  message: string;
  type: string;

  constructor(private activeModal: NgbActiveModal) { }

  abort(): void {
    this.activeModal.close();
  }

  confirm(id): void {
    this.activeModal.close(id);
  }

}
