import { ComponentFactoryResolver, ComponentRef, Directive, Input, ViewContainerRef } from '@angular/core';

import { SpinnerLoaderComponent } from '../components/utility/spinner-loader/spinner-loader.component';

@Directive({
  selector: '[spinnerLoader]'
})
export class SpinnerLoaderDirective {
  @Input() spinnerLoader :boolean;
  @Input() size?: number;

  spinnerRef: ComponentRef<any>;

  constructor(public viewContainerRef: ViewContainerRef, private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnChanges(changes: any) {
    for (const key in changes) {
      if (changes.hasOwnProperty(key)) {
        if (key === 'spinnerLoader' && typeof changes[key].currentValue === 'boolean') this.toggleSpinner(changes[key].currentValue);
      }
    }
  }

  toggleSpinner(closed: boolean) {
    if (!closed) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(SpinnerLoaderComponent);
      this.spinnerRef = this.viewContainerRef.createComponent(componentFactory);
      this.spinnerRef.instance.size = this.size || '3x';
    } else if (this.spinnerRef) {
      this.spinnerRef.destroy();
    }
  }

}
