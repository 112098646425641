import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NullValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import { authConfig } from './auth.config';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private oauthService: OAuthService, private router: Router) {}

  initAuth(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.oauthService.configure(authConfig);
      this.oauthService.tokenValidationHandler = new NullValidationHandler();

      this.oauthService
        .loadDiscoveryDocumentAndLogin()
        .then(() => {
          this.oauthService.setupAutomaticSilentRefresh();
          resolve(true);
        })
        .catch((err) => reject(err));
    });
  }

  logout(): void {
    if (this.oauthService.getIdToken()) {
      this.oauthService.logOut();
    } else {
      this.router.navigate(['']);
    }
  }
}
