import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { sortListByName } from 'src/app/functions/general';
import { AccountService } from 'src/app/services/account.service';
import { CustomerService } from 'src/app/services/customer.service';

// Modals
import { ConfirmDeleteModalComponent } from '../modals/confirm-delete-modal/confirm-delete-modal.component';

@Component({
  selector: 'customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
})
export class CustomersComponent implements OnInit, OnDestroy {
  customers;

  accounts;
  customerSelected;

  subscriptions: any = {};

  constructor(private router: Router, private customerService: CustomerService, private accountService: AccountService, private modalSrv: NgbModal) {}

  ngOnInit(): void {
    this.getCustomers();
  }

  getCustomers() {
    this.subscriptions['getCustomers'] = this.customerService.getCustomers().subscribe((data) => {
      this.customerSelected = null;
      this.customers = data;
      this.sortCustomers();
      this.sortAccountsPerCustomer();
    });
  }

  selectCutomer(customer) {
    this.customers.forEach((element) => {
      if (element.id != customer.id) {
        element.selected = false;
      }
    });
    customer.selected = !customer.selected;
    if (customer.selected) {
      this.customerSelected = customer;
    } else {
      this.customerSelected = null;
    }
  }

  goToNewCusotomer() {
    this.router.navigate(['customer-settings']);
  }

  goToNewAccount() {
    this.router.navigate([`customer/${this.customerSelected.id}/account-settings`]);
  }

  deleteCustomer(customer: any) {
    const modal = this.modalSrv.open(ConfirmDeleteModalComponent, { size: 'lg', centered: true });
    modal.componentInstance.title = 'Delete Customer';
    modal.componentInstance.type = 'customer';
    modal.componentInstance.elementId = customer.id;
    modal.componentInstance.elemName = customer.name;
    modal.closed.subscribe((id) => {
      // Do Delete
      if (id) {
        this.customerService.deleteCustomer(id).subscribe(() => this.getCustomers());
      }
    });
  }

  editAccount(account) {
    this.router.navigate([`customer/${this.customerSelected.id}/account-settings/${account.id}`]);
  }

  deleteAccount(account: any) {
    const modal = this.modalSrv.open(ConfirmDeleteModalComponent, { size: 'lg', centered: true });
    modal.componentInstance.title = 'Delete Account';
    modal.componentInstance.type = 'account';
    modal.componentInstance.elementId = account.id;
    modal.componentInstance.elemName = account.name;
    modal.closed.subscribe((id) => {
      // Do Delete
      if (id) {
        this.accountService.deleteAccount(id).subscribe(() => this.getCustomers());
      }
    });
  }

  private sortCustomers() {
    sortListByName(this.customers);
  }

  private sortAccountsPerCustomer() {
    if (this.customers) {
      this.customers.forEach((customer) => {
        sortListByName(customer.accounts);
      });
    }
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key) => {
      this.subscriptions[key].unsubscribe();
    });
  }
}
