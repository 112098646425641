export const sortListByName = (listOfEl: any[]) => {
  if (listOfEl) {
      listOfEl.sort((elA, elB) => {
        if (elA.name.toLowerCase() === elB.name.toLowerCase()) {
          return 0
        } else {
          return elA.name.toLowerCase() > elB.name.toLowerCase() ? 1 : -1;
        }
      })
    }
  }